import React, { useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Applogo } from "../../../../Routes/ImagePath/index";
import { useSelector } from "react-redux";
import useDatabase from "../../../../hooks/useDatabase";
import useAuthentication from "../../../../hooks/useAuthentication";

const ThemeSettings = () => {
  const user = useSelector((state) => state.user).value;
  const authData = useSelector((state) => state.user).auth;
  const { uploadFiles } = useDatabase();
  const { updateAuthProfileData } = useAuthentication();
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      return;
    }

    const url = await uploadFiles("avatars", image, user.uid);
    await updateAuthProfileData({ photoURL: url });
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              {/* Page Header */}
              <Breadcrumbs maintitle="Theme Settings" />
              {/* /Page Header */}
              <form onSubmit={handleSubmit}>
                {/* <div className="input-block row">
                  <label className="col-lg-3 col-form-label">
                    Website Name
                  </label>
                  <div className="col-lg-9">
                    <input
                      name="website_name"
                      className="form-control"
                      defaultValue="Dreamguy's Technologies"
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">رفع الشعار</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <span className="form-text text-muted">
                      <span>أبعاد الصورة المفضلة</span>
                      <span>{` 40 x 40`}</span>
                    </span>
                  </div>
                  <div className="col-lg-2">
                    <div className="img-thumbnail float-end">
                      <img
                        src={authData.photoURL || Applogo}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Favicon</label>
                  <div className="col-lg-7">
                    <input type="file" className="form-control" />
                    <span className="form-text text-muted">
                      Recommended image size is 16px x 16px
                    </span>
                  </div>
                  <div className="col-lg-2">
                    <div className="settings-image img-thumbnail float-end">
                      <img
                        src={Applogo}
                        className="img-fluid"
                        width={16}
                        height={16}
                        alt=""
                      />
                    </div>
                  </div>
                </div> */}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">حفظ</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default ThemeSettings;
