import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
// import funds2023 from "./2023.json";
// import funds2023p from "./2023p.json";
// import fundsTo2022 from "./1999_2022.json";
// import fundsTo2022p from "./1999_2022p.json";
import { useLocation } from "react-router-dom";
// import funds2023 from "../../../assets/json/provFunds/gemel/2023.json";
// import funds2023p from "../../../assets/json/provFunds/pensions/2023p.json";
// import fundsTo2022 from "../../../assets/json/provFunds/gemel/1999_2022.json";
// import fundsTo2022p from "../../../assets/json/provFunds/pensions/1999_2022p.json";
import FundFilters from "../../../components/FundFilters";
import { base_url } from "../../../base_urls";
import { FaArrowAltCircleDown } from "react-icons/fa";
import axios from "axios";
import { FaRegCalendarCheck, FaShekelSign } from "react-icons/fa6";
import { formatNumbers } from "../../../utils/openformat/numbers";

const FundsMain = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [filtersArrays, setFiltersArray] = useState({});
  const [filter, setFilter] = useState({
    FUND_NAME: "",
    MANAGING_CORPORATION_LEGAL_ID: "",
    REPORTING_YEAR: "",
  });
  const [filteredData, setFilteredData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    handleData();
  }, [location]);

  useEffect(() => {
    createColumns();
  }, []);

  const handleData = async () => {
    let arr = [];
    let url = "";

    if (location.pathname.includes("p")) {
      setTitle("صناديق التقاعد");
      url = location.pathname.includes("2023p")
        ? "api/provFunds/pensions/2023p.json"
        : location.pathname.includes("2024p")
        ? "api/provFunds/pensions/2024p.json"
        : "api/provFunds/pensions/1999_2022p.json";
    } else {
      setTitle("صناديق الادخار");
      url = location.pathname.includes("2023")
        ? "api/provFunds/gemel/2023.json"
        : location.pathname.includes("2023")
        ? "api/provFunds/gemel/2024.json"
        : "api/provFunds/gemel/1999_2022.json";
    }

    arr = await axios.get(base_url + url);

    setData(arr.data);
    setDisplayData(arr.data);
    handleCreateFiltersArrays(arr.data);
  };

  const createColumns = () => {
    const structure = [
      "FUND_ID",
      "FUND_NAME",
      "FUND_CLASSIFICATION",
      "CONTROLLING_CORPORATION",
      "MANAGING_CORPORATION",
      "REPORT_PERIOD",
      "INCEPTION_DATE",
      "TARGET_POPULATION",
      "SPECIALIZATION",
      "SUB_SPECIALIZATION",
      "DEPOSITS",
      "WITHDRAWLS",
      "INTERNAL_TRANSFERS",
      "NET_MONTHLY_DEPOSITS",
      "TOTAL_ASSETS",
      "AVG_ANNUAL_MANAGEMENT_FEE",
      "AVG_DEPOSIT_FEE",
      "MONTHLY_YIELD",
      "YEAR_TO_DATE_YIELD",
      "YIELD_TRAILING_3_YRS",
      "YIELD_TRAILING_5_YRS",
      "AVG_ANNUAL_YIELD_TRAILING_3YRS",
      "AVG_ANNUAL_YIELD_TRAILING_5YRS",
      "STANDARD_DEVIATION",
      "ALPHA",
      "SHARPE_RATIO",
      "LIQUID_ASSETS_PERCENT",
      "STOCK_MARKET_EXPOSURE",
      "FOREIGN_EXPOSURE",
      "FOREIGN_CURRENCY_EXPOSURE",
      "MANAGING_CORPORATION_LEGAL_ID",
      "REPORTING_YEAR",
    ];
    const translatedKeys = {
      FUND_ID: "معرف الصندوق",
      FUND_NAME: "اسم الصندوق",
      FUND_CLASSIFICATION: "تصنيف الصندوق",
      CONTROLLING_CORPORATION: "الشركة المسيطرة",
      MANAGING_CORPORATION: "الشركة المديرة",
      REPORT_PERIOD: "فترة التقرير",
      INCEPTION_DATE: "تاريخ التأسيس",
      TARGET_POPULATION: "الفئة المستهدفة",
      SPECIALIZATION: "التخصص",
      SUB_SPECIALIZATION: "التخصص الفرعي",
      DEPOSITS: "الإيداعات",
      WITHDRAWLS: "السحوبات",
      INTERNAL_TRANSFERS: "التحويلات الداخلية",
      NET_MONTHLY_DEPOSITS: "صافي الإيداعات الشهرية",
      TOTAL_ASSETS: "إجمالي الأصول",
      AVG_ANNUAL_MANAGEMENT_FEE: "متوسط رسوم الإدارة السنوية",
      AVG_DEPOSIT_FEE: "متوسط رسوم الإيداع",
      MONTHLY_YIELD: "العائد الشهري",
      YEAR_TO_DATE_YIELD: "عائد منذ بداية العام",
      YIELD_TRAILING_3_YRS: "عائد آخر 3 سنوات",
      YIELD_TRAILING_5_YRS: "عائد آخر 5 سنوات",
      AVG_ANNUAL_YIELD_TRAILING_3YRS: "متوسط العائد السنوي لآخر 3 سنوات",
      AVG_ANNUAL_YIELD_TRAILING_5YRS: "متوسط العائد السنوي لآخر 5 سنوات",
      STANDARD_DEVIATION: "الانحراف المعياري",
      ALPHA: "ألفا",
      SHARPE_RATIO: "نسبة شارب",
      LIQUID_ASSETS_PERCENT: "نسبة الأصول السائلة",
      STOCK_MARKET_EXPOSURE: "التعرض لسوق الأسهم",
      FOREIGN_EXPOSURE: "التعرض للأصول الأجنبية",
      FOREIGN_CURRENCY_EXPOSURE: "التعرض للعملات الأجنبية",
      MANAGING_CORPORATION_LEGAL_ID: "المعرف القانوني للشركة المديرة",
      REPORTING_YEAR: "سنة التقرير",
    };
    const arr = [];
    Object.keys(translatedKeys).forEach((item) => {
      arr.push({
        title: translatedKeys[item],
        dataIndex: item,
      });
    });

    setColumns(arr);
  };

  const handleCreateFiltersArrays = (data) => {
    const structure = [
      "FUND_CLASSIFICATION",
      "FUND_NAME",
      "REPORTING_YEAR",
      "FUND_ID",
      "MANAGING_CORPORATION_LEGAL_ID",
    ];
    let obj = getUniqueValues(data, structure);
    setFiltersArray(obj);
  };

  const handleFilterChange = (name, value) => {
    if (name === "reset") {
      setDisplayData(data);
      setFilter({
        FUND_NAME: "",
        MANAGING_CORPORATION_LEGAL_ID: "",
        REPORTING_YEAR: "",
      });
      return;
    }
    let obj = { ...filter, [name]: value };
    setFilter(obj);
    let list = [...data];
    console.log(obj);
    Object.keys(obj).forEach((key) => {
      if (obj[key]) list = list.filter((item) => item[key] == obj[key]);
    });

    setDisplayData(list);
    if (list.length > 11) {
      setFilteredData(list[11]); // Safeguard for index
    } else {
      setFilteredData(null);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid" style={{ overflowX: "auto" }}>
        <Breadcrumbs
          maintitle={title}
          title={t("dashboard")}
          subtitle={title}
        />

        {filteredData && <Headers filteredData={filteredData} />}

        <FundFilters
          filtersArrays={filtersArrays}
          action={handleFilterChange}
        />

        <Table columns={columns} dataSource={displayData} />
      </div>
    </div>
  );
};

export default FundsMain;

function getUniqueValues(array, keys) {
  const result = {};
  keys.forEach((key) => {
    result[key] = [...new Set(array.map((item) => item[key]))];
  });
  return result;
}

const Headers = ({ filteredData }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-group m-b-30">
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">الفترة المالية</span>
                  </div>
                  <div>
                    <span className="text-success">
                      <FaRegCalendarCheck size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3 className=" mt-3">{filteredData.REPORTING_YEAR} - 12</h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">الايداعات الشهرية</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.DEPOSITS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.DEPOSITS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {/* {filteredData.DEPOSITS * 1000000} */}
                  {formatNumbers(filteredData.DEPOSITS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">مجموع السحوبات الشهرية</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.WITHDRAWLS < 0
                          ? "text-danger mt-3"
                          : "text-success mt-3"
                      }
                    >
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.WITHDRAWLS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.WITHDRAWLS * 1000000)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-group m-b-30">
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">مجموع التحويلات الداخلية </span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.INTERNAL_TRANSFERS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.INTERNAL_TRANSFERS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.INTERNAL_TRANSFERS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">صافي الايداعات الشهرية </span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.NET_MONTHLY_DEPOSITS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.NET_MONTHLY_DEPOSITS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {" "}
                  {formatNumbers(filteredData.NET_MONTHLY_DEPOSITS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">اجمالي الاصول</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.TOTAL_ASSETS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.TOTAL_ASSETS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.TOTAL_ASSETS * 1000000)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
