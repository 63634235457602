import {
  collection,
  doc,
  setDoc,
  getDocs,
  getDoc,
  deleteDoc,
  Timestamp,
  query,
  onSnapshot,
  orderBy,
  where,
  addDoc,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth, db, storage } from "../utils/Firebase";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIndexData } from "../user";

const useDatabase = () => {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.user).value?.uid;

  const getData = async (path) => {
    const colRef = collection(db, path);
    const arr = [];
    const snapshot = await getDocs(colRef);
    snapshot.docs.forEach((doc) => {
      arr.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    return arr;
  };

  const createCollectionRef = (path) => {
    console.log(path);
    if (!path.includes("/")) {
      return collection(db, path);
    }

    const segments = path.split("/");

    if (segments.length % 2 === 0) {
      throw new Error(
        "Invalid path. The path must point to a collection, not a document."
      );
    }

    let ref = db;

    for (let i = 0; i < segments.length; i++) {
      if (i % 2 === 0) {
        ref = collection(ref, segments[i]);
      } else {
        ref = doc(ref, segments[i]);
      }
    }

    return ref;
  };

  const setDocument = async (path, data, id) => {
    const colRef = collection(db, path);
    try {
      await setDoc(doc(colRef, id), data);
    } catch (err) {
      console.log(err);
      // dispatch(
      //   show({
      //     message: "فشل التعديل",
      //     class: "danger",
      //     title: "حاول مرة أخرى",
      //   })
      // );
      // setStaus(false);
    }
  };

  const deleteDocument = async (path, data) => {
    // setLoading(true);

    const colRef = collection(db, path);
    await deleteDoc(doc(colRef, data.id));
    // dispatch(
    //   show({
    //     message: "تم الحذف بنجاح",
    //     class: "info",
    //     title: "",
    //   })
    // );
    getData(path);
    // setStaus(true);
  };

  const setDocumentMultiLayer = async (
    type,
    data,
    fileId,
    companyName,
    taxYear
  ) => {
    const colRef = collection(db, getPath(type));
    const indexRef = collection(db, `users/${uid}/uploadeDataIndex`);

    try {
      const docRef = await addDoc(colRef, data);
      const indexData = {
        fileType: type,
        fileId,
        companyName: companyName,
        year: taxYear,
        docId: docRef.id,
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
      };
      const addIndex = await addDoc(indexRef, indexData);
      const indexes = await getData(`users/${uid}/uploadeDataIndex`);
      dispatch(setIndexData(indexes));
    } catch (err) {
      console.log(err);
    }
  };

  const getPath = (type) => {
    switch (type) {
      case "6111":
        return `users/${uid}/6111s`;
      case "open format":
        return `users/${uid}/ofs`;
      case "126":
        return `users/${uid}/126s`;
      default:
        return null;
    }
  };

  const uploadFiles = async (path, file, fileName, previous) => {
    // setLoading(true);
    if (previous) await deleteFile(previous);
    const storeRef = ref(storage, `${path}/${fileName}`);
    const response = await uploadBytes(storeRef, file);
    const url = await getDownloadURL(response.ref);
    // if (url) {
    //   dispatch(
    //     show({
    //       message: "تم رفع الصورة بنجاح",
    //       class: "success",
    //       title: "",
    //     })
    //   );
    // }
    // setLoading(false);
    return url;
  };

  const deleteFile = async (refPath) => {
    const fileRef = ref(storage, refPath);
    try {
      await deleteObject(fileRef);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  return {
    getData,
    setDocument,
    deleteDocument,
    setDocumentMultiLayer,
    uploadFiles,
  };
};

export default useDatabase;
