import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import useFundData from "../../useFundData";
import { useParams } from "react-router-dom";
import Select from "react-select";
import DashboardWidget from "./DashboardWidget";
import DashboardGraph from "./DashboardGraph";
import OfferedJobs from "./OfferedJobs";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";
import AppliedJobsTable from "./AppliedJobsTable";
import { useLocation } from "react-router-dom";
import { base_url } from "../../../../../base_urls";
import { Table } from "antd";
import UserDataTable from "./UserDataTable";
import FundFilters from "../../../../../components/FundFilters";
import InterviewingTables from "./DashboardTabs/Interviewing/InterviewingTables";
import UserOfferedJobs from "../../../Administration/Jobs/UserJob/UserOfferedJobs";
import UserOfferedJobsTable from "./UserOfferedJobsTable";
import FundsDashboardHeader from "./FundsDashboardHeader";
const FundsDashboard = () => {
  const { id, year } = useParams();
  const location = useLocation();

  // Determine whether the current page URL contains "pension"
  const isPensionPage = location.pathname.includes("funds-details/pensions");

  const [selected, setSelected] = useState({});
  const [month, setMonth] = useState(12);
  const {
    title,
    columns,
    data,
    displayData,
    filtersArrays,
    loading,
    handleData,
    filterByYear,
  } = useFundData(base_url, year, id, isPensionPage);

  const [fundDetails, setFundDetails] = useState([]);
  const [filter, setFilter] = useState({
    FUND_ID: "",
    REPORTING_YEAR: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await handleData();
    // No need to manually set `displayData` here since it's handled by the hook
  };

  useEffect(() => {
    // Update fund details based on the displayData and selected month
    if (displayData && displayData.length > 0) {
      if (displayData.length < month) {
        setFundDetails(displayData[displayData.length - 1]);
        setMonth(displayData.length);
      } else setFundDetails(displayData[month - 1]);
    }
  }, [displayData, month]);

  const handleFilterChange = (name, value) => {
    if (name === "reset") {
      setFilter({
        FUND_ID: "",
        REPORTING_YEAR: "",
      });
      getData();
      setMonth(12);

      return;
    }

    setFilter((values) => ({ ...values, [name]: value }));
    if (name === "REPORTING_YEAR") {
      filterByYear(value); // Filter by year using the hook function
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return loading ? (
    <div className="page-wrapper">
      <div className="content container-fluid spinner-custom">
        <div className="m-auto text-right  mt-10">
          <div className="spinner-grow text-primary m-2" role="status" />
          <div className="spinner-grow text-primary m-2" role="status" />
          <div className="spinner-grow text-primary m-2" role="status" />
          <div className="spinner-grow text-primary m-2" role="status" />
        </div>
      </div>
    </div>
  ) : (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={
            isPensionPage
              ? " تفاصيل صندوق التقاعد رقم : " + id
              : " تفاصيل صندوق الادخار رقم : " + id
          }
          title="Dashboard"
          subtitle="تفاصيل"
          modal="#print"
          name="hello"
        />

        <div className="row">
          {/* Render the table with the filtered data */}
          {/* <Table columns={fColumns} dataSource={displayData2} /> */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4 m-b-20">
                    {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                    <ul className="list-unstyled mb-0">
                      <li>
                        <span className="text-primary"> اسم الصندوق: </span>{" "}
                        {fundDetails?.FUND_NAME}
                      </li>
                      {/* <li className='text-primary'>{companyData.taxFileNumber}</li> */}
                      {/* <li> {type40Data.companyNumber}</li> */}
                      <li>
                        <span className="text-primary"> تصنيف الصندوق : </span>
                        {fundDetails?.FUND_CLASSIFICATION}
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-5 m-b-20">
                    <ul className="list-unstyled mb-0 text-center">
                      <li>
                        <h3 className="text-primary">
                          {fundDetails?.REPORTING_YEAR}- {month}
                        </h3>
                      </li>

                      <li>
                        {" "}
                        <span className="text-primary">
                          معرف الصندوق :
                        </span>{" "}
                        {fundDetails?.FUND_ID}
                      </li>
                      <li>
                        {" "}
                        <span className="text-primary">رقم الشركة :</span>{" "}
                        {fundDetails?.MANAGING_CORPORATION_LEGAL_ID}
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3 m-b-20">
                    <ul className="list-unstyled mb-0 text-right">
                      <li>
                        <span className="text-primary"> الشركة المديرة: </span>
                        {fundDetails?.MANAGING_CORPORATION}
                      </li>
                      <li>
                        <span className="text-primary"> الشركة المسيطرة: </span>
                        {fundDetails?.CONTROLLING_CORPORATION}
                      </li>

                      {/* <li> {companyData.taxFileNumber} </li> */}
                      {/* <li> {type40Data.phoneNumber}</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FundsDashboardHeader />
        <div className="row filter-row justify-content-around">
          <div className="col-sm-2 col-md-2 col-lg-2  ">
            <div className="input-block form-focus select-focus  ">
              <Select
                options={[
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                  { value: 3, label: "3" },
                  { value: 4, label: "4" },
                  { value: 5, label: "5" },
                  { value: 6, label: "6" },
                  { value: 7, label: "7" },
                  { value: 8, label: "8" },
                  { value: 9, label: "9" },
                  { value: 10, label: "10" },
                  { value: 11, label: "11" },
                  { value: 12, label: "12" },
                ]}
                placeholder="اختر الشهر"
                styles={customStyles}
                value={selected?.month || null}
                onChange={(el) => setMonth(el.value)}
              />
              <label className="focus-label mb-5">{"اختر الشهر "}</label>
            </div>
          </div>
          <div className="col-sm-10 col-md-10 col-lg-10 ">
            <FundFilters
              filtersArrays={filtersArrays}
              action={handleFilterChange}
            />
          </div>
        </div>
        <DashboardWidget data={fundDetails} />

        <div className="tab-content">
          <div className="tab-pane show active" id="basictab1">
            <div className="card">
              <div className="card-header">
                <h2 className="text-primary card-title text-center pt-2">
                  تفاصيل الصندوق{" "}
                </h2>
              </div>
            </div>
            <DashboardGraph current={displayData || []} />
            <OfferedJobs data={displayData} />
            {/* <AppliedJobsTable /> */}
          </div>
          <div className="tab-pane" id="basictab2">
            <div className="card">
              <div className="card-header">
                <h2 className="text-primary card-title text-center pt-2">
                  السنوات المالية
                </h2>
              </div>
            </div>
            <UserDataTable data={data} />
          </div>
          <div className="tab-pane" id="basictab3">
            <div className="card">
              <div className="card-header">
                <h2 className="text-primary card-title text-center pt-2">
                  معلومات الشركة
                </h2>
              </div>
            </div>
            <InterviewingTables
              id={fundDetails?.MANAGING_CORPORATION_LEGAL_ID}
            />
          </div>
          <div className="tab-pane" id="basictab4">
            <div className="card">
              <div className="card-header">
                <h2 className="text-primary card-title text-center pt-2">
                  مساعد AI
                </h2>
              </div>
            </div>
            {/* <UserOfferedJobsTable /> */}
          </div>
        </div>

        <DeleteModal Name="Delete" />
      </div>
    </div>
  );
};

export default FundsDashboard;
function getUniqueValues(array, keys) {
  const result = {};
  keys.forEach((key) => {
    result[key] = [...new Set(array.map((item) => item[key]))];
  });
  return result;
}
