/* eslint-disable react/prop-types */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  alphaModalData,
  unavailableServiceModal,
} from "../../../../../assets/json/provFunds/infoModalsData.js";
import ReusableInfoModal from "../../../../../components/modelpopup/ReusableInfoModal ";
const FundsDashboardHeader = (props) => {
  const location = useLocation();
  let pathname = location.pathname;

  return (
    <div className="card">
      <div className="card-body">
        <ul className="nav nav-tabs nav-tabs-solid nav-justified">
          <li className="nav-item">
            <Link
              className="nav-link active"
              to="#basictab1"
              data-bs-toggle="tab"
            >
              تفاصيل الصندوق
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="#basictab2" data-bs-toggle="tab">
              السنوات المالية{" "}
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname.includes("saved-jobs") ? "active" : ""
              }`}
              to="/saved-jobs"
            >
              Saved
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname.includes("applied-jobs") ? "active" : ""
              }`}
              to="/applied-jobs"
            >
              Applied
            </Link>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="#basictab3" data-bs-toggle="tab">
              معلومات الشركة
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#print"
              // to="#basictab4"
              data-bs-toggle="modal"
              data-bs-target={`#${unavailableServiceModal.modalId}`}
              // data-bs-toggle="tab"
            >
              مساعد AI
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname.includes("visited-jobs") ? "active" : ""
              }`}
              to="/visited-jobs"
            >
              Visitied{" "}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname.includes("archived-jobs") ? "active" : ""
              }`}
              to="/archived-jobs"
            >
              Archived{" "}
            </Link>
          </li> */}
        </ul>
      </div>
      <ReusableInfoModal
        modalId={unavailableServiceModal.modalId}
        title={unavailableServiceModal.title}
        sections={unavailableServiceModal.sections}
      />
    </div>
  );
};

export default FundsDashboardHeader;
