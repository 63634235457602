import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Table } from "antd";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { base_url } from "../../../../../base_urls";
import FundFilters from "../../../../../components/FundFilters";
import { FaPercent, FaRegCalendarCheck, FaShekelSign } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { formatNumbers } from "../../../../../utils/openformat/numbers";
import {
  alphaModalData,
  internalTransfersData,
  sharpeRatioModalData,
} from "../../../../../assets/json/provFunds/infoModalsData.js";
import ReusableInfoModal from "../../../../../components/modelpopup/ReusableInfoModal ";
import useFundData from "../../useFundData.jsx";
const Pensions = () => {
  const location = useLocation();

  const isPensionPage = location.pathname.includes("capital-market/pensions");

  const { id, year } = useParams();
  const [displayData, setDisplayData] = useState([]);
  const [filtersArrays, setFiltersArray] = useState({});
  const [columns, setColumns] = useState([]);
  const [filter, setFilter] = useState({
    FUND_NAME: "",
    MANAGING_CORPORATION_LEGAL_ID: "",
    REPORTING_YEAR: "",
  });
  const [filteredTicket, setFilteredTicket] = useState(null);
  const { allData, handleData, loading } = useFundData(
    base_url,
    year,
    id,
    isPensionPage
  );
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await handleData();
    setDisplayData(allData);
    // createColumns();
    // handleCreateFiltersArrays(allData);
  };
  useEffect(() => {
    if (allData && allData.length > 0) {
      setDisplayData(allData);
      // console.log("all data? ", allData);
      createColumns();
      handleCreateFiltersArrays(allData);
    }
  }, [allData]);

  const handleFilterChange = (name, value) => {
    if (name === "reset") {
      setDisplayData(allData);
      setFilter({
        FUND_NAME: "",
        MANAGING_CORPORATION_LEGAL_ID: "",
        REPORTING_YEAR: "",
      });
      return;
    }
    let obj = { ...filter, [name]: value };
    setFilter(obj);
    let list = [...allData];
    Object.keys(obj).forEach((key) => {
      if (obj[key]) list = list.filter((item) => item[key] == obj[key]);
    });

    setDisplayData(list);
    if (list.length > 11) {
      setFilteredTicket(list[11]); // Safeguard for index
    } else {
      setFilteredTicket(null);
    }
  };
  const handleCreateFiltersArrays = (data) => {
    const structure = [
      "FUND_CLASSIFICATION",
      "FUND_NAME",
      "REPORTING_YEAR",
      "FUND_ID",
      "MANAGING_CORPORATION_LEGAL_ID",
    ];
    let obj = getUniqueValues(data, structure);
    setFiltersArray(obj);
  };
  const createColumns = () => {
    const structure = [
      "FUND_ID",
      "FUND_NAME",
      "FUND_CLASSIFICATION",
      "CONTROLLING_CORPORATION",
      "MANAGING_CORPORATION",
      "REPORT_PERIOD",
      "INCEPTION_DATE",
      "TARGET_POPULATION",
      "SPECIALIZATION",
      "SUB_SPECIALIZATION",
      "DEPOSITS",
      "WITHDRAWLS",
      "INTERNAL_TRANSFERS",
      "NET_MONTHLY_DEPOSITS",
      "TOTAL_ASSETS",
      "AVG_ANNUAL_MANAGEMENT_FEE",
      "AVG_DEPOSIT_FEE",
      "MONTHLY_YIELD",
      "YEAR_TO_DATE_YIELD",
      "YIELD_TRAILING_3_YRS",
      "YIELD_TRAILING_5_YRS",
      "AVG_ANNUAL_YIELD_TRAILING_3YRS",
      "AVG_ANNUAL_YIELD_TRAILING_5YRS",
      "STANDARD_DEVIATION",
      "ALPHA",
      "SHARPE_RATIO",
      "LIQUID_ASSETS_PERCENT",
      "STOCK_MARKET_EXPOSURE",
      "FOREIGN_EXPOSURE",
      "FOREIGN_CURRENCY_EXPOSURE",
      "MANAGING_CORPORATION_LEGAL_ID",
      "REPORTING_YEAR",
    ];
    const translatedKeys = {
      FUND_ID: "معرف الصندوق",
      FUND_NAME: "اسم الصندوق",
      FUND_CLASSIFICATION: "تصنيف الصندوق",
      CONTROLLING_CORPORATION: "الشركة المسيطرة",
      MANAGING_CORPORATION: "الشركة المديرة",
      REPORT_PERIOD: "فترة التقرير",
      INCEPTION_DATE: "تاريخ التأسيس",
      TARGET_POPULATION: "الفئة المستهدفة",
      SPECIALIZATION: "التخصص",
      SUB_SPECIALIZATION: "التخصص الفرعي",
      DEPOSITS: "الإيداعات",
      WITHDRAWLS: "السحوبات",
      INTERNAL_TRANSFERS: "التحويلات الداخلية",
      NET_MONTHLY_DEPOSITS: "صافي الإيداعات الشهرية",
      TOTAL_ASSETS: "إجمالي الأصول",
      AVG_ANNUAL_MANAGEMENT_FEE: "متوسط رسوم الإدارة السنوية",
      AVG_DEPOSIT_FEE: "متوسط رسوم الإيداع",
      MONTHLY_YIELD: "العائد الشهري",
      YEAR_TO_DATE_YIELD: "عائد منذ بداية العام",
      YIELD_TRAILING_3_YRS: "عائد آخر 3 سنوات",
      YIELD_TRAILING_5_YRS: "عائد آخر 5 سنوات",
      AVG_ANNUAL_YIELD_TRAILING_3YRS: "متوسط العائد السنوي لآخر 3 سنوات",
      AVG_ANNUAL_YIELD_TRAILING_5YRS: "متوسط العائد السنوي لآخر 5 سنوات",
      STANDARD_DEVIATION: "الانحراف المعياري",
      ALPHA: "ألفا",
      SHARPE_RATIO: "نسبة شارب",
      LIQUID_ASSETS_PERCENT: "نسبة الأصول السائلة",
      STOCK_MARKET_EXPOSURE: "التعرض لسوق الأسهم",
      FOREIGN_EXPOSURE: "التعرض للأصول الأجنبية",
      FOREIGN_CURRENCY_EXPOSURE: "التعرض للعملات الأجنبية",
      MANAGING_CORPORATION_LEGAL_ID: "المعرف القانوني للشركة المديرة",
      REPORTING_YEAR: "سنة التقرير",
    };
    const arr = [];
    Object.keys(translatedKeys).forEach((item) => {
      arr.push({
        title: translatedKeys[item],
        dataIndex: item,
        render:
          item === "FUND_ID"
            ? (text, record) => (
                <a
                  href={`/funds/funds-details/pensions/${text}/${
                    record.REPORTING_YEAR ? record.REPORTING_YEAR : 2024
                  }`}
                >
                  {text}
                </a>
              )
            : undefined,
      });
    });

    setColumns(arr);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="صناديق التقاعد "
            title="الرئيسية"
            subtitle="تفاصيل "
            name="رجوع"
          />
          {filteredTicket != null && (
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 m-b-20">
                        {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                        <ul className="list-unstyled mb-0">
                          <li>
                            <span className="text-primary"> اسم الصندوق: </span>{" "}
                            {filteredTicket.FUND_NAME}
                          </li>
                          {/* <li className='text-primary'>{companyData.taxFileNumber}</li> */}
                          {/* <li> {type40Data.companyNumber}</li> */}
                          <li>
                            <span className="text-primary">
                              {" "}
                              تصنيف الصندوق :{" "}
                            </span>
                            {filteredTicket.FUND_CLASSIFICATION}
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-5 m-b-20">
                        <ul className="list-unstyled mb-0 text-center">
                          <li>
                            <h3 className="text-primary">
                              {filteredTicket.REPORTING_YEAR}
                            </h3>
                          </li>

                          <li>
                            {" "}
                            <span className="text-primary">
                              معرف الصندوق :
                            </span>{" "}
                            {filteredTicket.FUND_ID}
                          </li>
                          <li>
                            {" "}
                            <span className="text-primary">
                              رقم الشركة :
                            </span>{" "}
                            {filteredTicket.MANAGING_CORPORATION_LEGAL_ID}
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-3 m-b-20">
                        <ul className="list-unstyled mb-0 text-right">
                          <li>
                            <span className="text-primary">
                              {" "}
                              الشركة المديرة:{" "}
                            </span>
                            {filteredTicket.MANAGING_CORPORATION}
                          </li>
                          <li>
                            <span className="text-primary">
                              {" "}
                              الشركة المسيطرة:{" "}
                            </span>
                            {filteredTicket.CONTROLLING_CORPORATION}
                          </li>

                          {/* <li> {companyData.taxFileNumber} </li> */}
                          {/* <li> {type40Data.phoneNumber}</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <TicketFilter /> */}
          {!loading && (
            <FundFilters
              filtersArrays={filtersArrays}
              action={handleFilterChange}
            />
          )}
          {filteredTicket != null && (
            <div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-group m-b-30">
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">الفترة المالية</span>
                          </div>
                          <div>
                            <span className="text-success">
                              <FaRegCalendarCheck size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3 className=" mt-3">
                          {filteredTicket.REPORTING_YEAR} - 12
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">الايداعات الشهرية</span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.DEPOSITS < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.DEPOSITS < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {/* {filteredTicket.DEPOSITS * 1000000} */}
                          {formatNumbers(filteredTicket.DEPOSITS * 1000000)}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">
                              مجموع السحوبات الشهرية
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.WITHDRAWLS < 0
                                  ? "text-danger mt-3"
                                  : "text-success mt-3"
                              }
                            >
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.WITHDRAWLS < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(filteredTicket.WITHDRAWLS * 1000000)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-group m-b-30">
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block ">
                              <button
                                className="btn btn-info-modal p-0 border-0 tooltip-container"
                                data-bs-toggle="modal"
                                data-bs-target={`#${internalTransfersData.modalId}`}
                              >
                                <FaInfoCircle
                                  className=" text-primary m-r-5 info-icon icon "
                                  size={22}
                                />
                                <div class="tooltip-text">توضيح!</div>
                              </button>
                              مجموع التحويلات الداخلية{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.INTERNAL_TRANSFERS < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.INTERNAL_TRANSFERS < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(
                            filteredTicket.INTERNAL_TRANSFERS * 1000000
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">
                              صافي الايداعات الشهرية{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.NET_MONTHLY_DEPOSITS < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.NET_MONTHLY_DEPOSITS < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {" "}
                          {formatNumbers(
                            filteredTicket.NET_MONTHLY_DEPOSITS * 1000000
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">اجمالي الاصول</span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.TOTAL_ASSETS < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.TOTAL_ASSETS < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(filteredTicket.TOTAL_ASSETS * 1000000)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-group m-b-30">
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block ">
                              <button
                                className="btn btn-info-modal p-0 border-0 tooltip-container"
                                data-bs-toggle="modal"
                                data-bs-target={`#${alphaModalData.modalId}`}
                              >
                                <FaInfoCircle
                                  className=" text-primary m-r-5 info-icon icon "
                                  size={22}
                                />
                                <div class="tooltip-text">توضيح!</div>
                              </button>
                              مؤشر ألفا{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.ALPHA < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.ALPHA < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(filteredTicket.ALPHA)}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">
                              <button
                                className="btn btn-info-modal p-0 border-0 tooltip-container"
                                data-bs-toggle="modal"
                                data-bs-target={`#${sharpeRatioModalData.modalId}`}
                              >
                                <FaInfoCircle
                                  className=" text-primary m-r-5 info-icon icon "
                                  size={22}
                                />
                                <div class="tooltip-text">توضيح!</div>
                              </button>
                              مؤشر شارب{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.SHARPE_RATIO < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.SHARPE_RATIO < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {" "}
                          {formatNumbers(filteredTicket.SHARPE_RATIO)}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">نسبة الاصول السائلة</span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.LIQUID_ASSETS_PERCENT < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              <FaPercent size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.LIQUID_ASSETS_PERCENT < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(filteredTicket.LIQUID_ASSETS_PERCENT)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-group m-b-30">
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block ">
                              التعرض لسوق الأسهم{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.STOCK_MARKET_EXPOSURE < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.STOCK_MARKET_EXPOSURE < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(
                            filteredTicket.STOCK_MARKET_EXPOSURE * 1000000
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">
                              التعرض للأصول الأجنبية{" "}
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.FOREIGN_EXPOSURE < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {/* {user.percentage} */}
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.FOREIGN_EXPOSURE < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {" "}
                          {formatNumbers(
                            filteredTicket.FOREIGN_EXPOSURE * 1000000
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="card m-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">
                              {" "}
                              التعرض للعملات الأجنبية
                            </span>
                          </div>
                          <div>
                            <span
                              className={
                                filteredTicket.FOREIGN_CURRENCY_EXPOSURE < 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              <FaShekelSign size={20} />
                            </span>
                          </div>
                        </div>
                        <div
                          className="progress mb-2"
                          style={{ height: "2px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "100%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                        <h3
                          className={
                            filteredTicket.FOREIGN_CURRENCY_EXPOSURE < 0
                              ? "text-danger mt-3"
                              : "text-success mt-3"
                          }
                        >
                          {formatNumbers(
                            filteredTicket.FOREIGN_CURRENCY_EXPOSURE * 1000000
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ========================================================================= */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">
                <div className="card-header">
                  <h2 className="card-title mb-0 text-primary p-2 center">
                    {" "}
                    تفاصيل صناديق التقاعد{" "}
                  </h2>
                </div>
                <div className="card-body">
                  <div className="table-responsive funds-table">
                    {loading ? (
                      <div
                        className="spinner-border text-primary m-2"
                        role="status"
                      />
                    ) : (
                      <Table
                        className="datatable table-striped funds-table"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={displayData}
                        pagination={{ pageSize: 12 }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ========================================================================= */}

          {/* <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
      {/* <InfoModalInternalTransfers /> */}
      <ReusableInfoModal
        modalId={internalTransfersData.modalId}
        title={internalTransfersData.title}
        sections={internalTransfersData.sections}
      />
      <ReusableInfoModal
        modalId={alphaModalData.modalId}
        title={alphaModalData.title}
        sections={alphaModalData.sections}
      />
      <ReusableInfoModal
        modalId={sharpeRatioModalData.modalId}
        title={sharpeRatioModalData.title}
        sections={sharpeRatioModalData.sections}
      />
    </>
  );
};

export default Pensions;
function getUniqueValues(array, keys) {
  const result = {};
  keys.forEach((key) => {
    result[key] = [...new Set(array.map((item) => item[key]))];
  });
  return result;
}
