import React, { useEffect, useState } from "react";
import Select from "react-select";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { Applogo } from "../../../../Routes/ImagePath/index";
import useDatabase from "../../../../hooks/useDatabase";
import useAuthentication from "../../../../hooks/useAuthentication";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const user = useSelector((state) => state.user).value;
  const authData = useSelector((state) => state.user).auth;
  const { setDocument, uploadFiles } = useDatabase();
  const { getCurrentUser, updateAuthProfileData } = useAuthentication();
  const [image, setImage] = useState(null);
  const [info, setInfo] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInfo(user);
  }, []);

  const selectUSA = [
    { label: "فلسطين", value: "option1" },
    { label: "United Kingdom", value: "option2" },
  ];

  const handleInfo = (name, value) => {
    setInfo((values) => ({ ...values, [name]: value }));
  };

  const handeSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    for (let key in info) {
      if (info[key] != null) data[key] = info[key];
    }
    await setDocument("users", data, info.uid);
    getCurrentUser();
  };

  const handleImage = async (file) => {
    // const file=e.target.files[0]
    if (file) {
      console.log("here");
      const url = await uploadFiles("avatars", file, user.uid);
      await updateAuthProfileData({ photoURL: url });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              {/* Page Header */}
              <Breadcrumbs maintitle={"إعدادات الشركة"} />

              {/* /Page Header */}
              <form onSubmit={handeSubmit}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        اسم الموظف <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        // defaultValue="Dreamguy's Technologies"
                        name="name"
                        value={info?.name}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">رقم الهوية</label>
                      <input
                        className="form-control "
                        // defaultValue="Daniel Porter"
                        type="text"
                        name="idNumber"
                        value={info?.idNumber}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">المسمى الوظيفي</label>
                      <input
                        className="form-control "
                        // defaultValue="Daniel Porter"
                        type="text"
                        name="role"
                        value={info?.role}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">رفع الشعار</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => handleImage(e.target.files[0])}
                    />
                    <span className="form-text text-muted">
                      <span>أبعاد الصورة المفضلة</span>
                      <span>{` 40 x 40`}</span>
                    </span>
                  </div>
                  <div className="col-lg-2">
                    <div className="img-thumbnail float-end">
                      <img
                        src={authData.photoURL || Applogo}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        اسم الشركة <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        // defaultValue="Dreamguy's Technologies"
                        name="companyName"
                        value={info?.companyName}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">مسؤول التواصل</label>
                      <input
                        className="form-control "
                        // defaultValue="Daniel Porter"
                        type="text"
                        name="contactName"
                        value={info?.contactName}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">العنوان</label>
                      <input
                        className="form-control "
                        // defaultValue="3864 Quiet Valley Lane, Sherman Oaks, CA, 91403"
                        type="text"
                        name="address"
                        value={info?.address}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">الدولة</label>
                      <Select
                        options={selectUSA}
                        placeholder="USA"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">المدينة</label>
                      <input
                        className="form-control"
                        // defaultValue="Sherman Oaks"
                        type="text"
                        name="city"
                        value={info?.city}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">المحافظة</label>
                      <input
                        className="form-control"
                        // defaultValue="Sherman Oaks"
                        type="text"
                        name="state"
                        value={info?.state}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">الرمز البريدي</label>
                      <input
                        className="form-control"
                        // defaultValue={91403}
                        type="text"
                        name="postalCode"
                        value={info?.postalCode}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        البريد الالكتروني
                      </label>
                      <input
                        className="form-control"
                        // defaultValue="danielporter@example.com"
                        type="email"
                        name="companyEmail"
                        value={info?.companyEmail || info.email}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">الهاتف الأرضي</label>
                      <input
                        className="form-control"
                        // defaultValue="818-978-7102"
                        type="text"
                        name="homePhone"
                        value={info?.homePhone}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">الهاتف المحمول</label>
                      <input
                        className="form-control"
                        // defaultValue="818-635-5579"
                        type="text"
                        name="phone"
                        value={info?.phone}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">فاكس</label>
                      <input
                        className="form-control"
                        // defaultValue="818-978-7102"
                        type="text"
                        name="fax"
                        value={info?.fax}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        رابط الموقع الإلكتروني
                      </label>
                      <input
                        className="form-control"
                        // defaultValue="https://www.example.com"
                        type="text"
                        name="website"
                        value={info?.website}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        رقم ملف ضريبة الدخل
                      </label>
                      <input
                        className="form-control"
                        // defaultValue="818-635-5579"
                        type="text"
                        name="vatNumber"
                        value={info?.vatNumber}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        رقم ملف ضريبة القيمة المضافة
                      </label>
                      <input
                        className="form-control"
                        // defaultValue="818-978-7102"
                        type="text"
                        name="taxNumber"
                        value={info?.taxNumber}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        رقم ملف الاقتطاعات
                      </label>
                      <input
                        className="form-control"
                        // defaultValue="818-978-7102"
                        type="text"
                        name="deductionNumber"
                        value={info?.deductionNumber}
                        onChange={(e) =>
                          handleInfo(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">حفظ</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default Settings;
