import React from "react";
import { Link } from "react-router-dom";

const AccordionModal = (props) => {
  return (
    <>
      {/* /.modal */}
      <div
        id="accordion-modal"
        className="modal custom-modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        // style={{ display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content ">
            <div className="modal-header">
              <h4 className="modal-title text-primary">توضيح </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="accordion">
                <div className="card mb-0 p-2">
                  <div className="card-header bg-dark" id="headingOne">
                    <h4 className="m-0">
                      <a
                        href="#collapseOne"
                        className="text-primary "
                        data-bs-toggle="collapse"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        ما الذي يمكن استخلاصه من خلال النظام؟
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body ">
                      <p>
                        {" "}
                        يُنتج النظام رسومًا بيانية وجداول لقياس ومقارنة عوائد
                        صناديق التقاعد (مقارنة بين عدة صناديق)، والشركات المديرة
                        (مقارنة بين عدة شركات)، والمؤسسات المسيطرة (مقارنة بين
                        عدة مؤسسات مسيطرة)، أو قياس ومقارنة العوائد حسب نوع
                        الصندوق، لكل فترة زمنية تزيد عن سنة وفي أي نقطة زمنية.
                        كما يتيح النظام مقارنة العوائد بمؤشرات السوق المختارة
                        مثل: مؤشر تل أبيب 25، مؤشر تل أبيب 100، مؤشر السندات
                        الحكومية القابلة للتداول، وغيرها.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card mb-0 p-2">
                  <div className="card-header bg-dark" id="headingTwo">
                    <h4 className="m-0">
                      <a
                        href="#collapseTwo"
                        className="collapsed text-primary"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        هيكلية النظام
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <p> يمكن استخراج نوعين من التقارير باستخدام النظام</p>
                      <ol className=" p-2">
                        <li className="p-2">
                          <b>العوائد : </b>مقارنة لفترة 12 شهرًا أو أكثر.
                        </li>
                        <li className=" p-2">
                          {" "}
                          <b>عام : </b> مقارنة بمؤشرات السوق
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="card mb-0 p-2">
                  <div className="card-header bg-dark" id="headingThree">
                    <h4 className="m-0">
                      <a
                        href="#collapseThree"
                        className="collapsed text-primary"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        تفاصيل التقارير المُنتَجة من النظام
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <ol className="p-2">
                        <li className="p-2">
                          عند اختيار نوع تقرير "العوائد" والاستعلام المطلوب،
                          يعرض النظام جدولًا يوضح العائد التراكمي
                          للصناديق/الشركات المديرة/المؤسسات المسيطرة/أنواع
                          الصناديق المختارة - حسب اختيار المستخدم. كما يبين
                          الجداول رصيد أصولها، رسوم الإدارة، وصافي المدخرات خلال
                          الفترة المحددة
                        </li>
                        <li className="p-2">
                          عند النقر على اسم أي كيان، يتم فتح نافذة تحتوي على
                          جداول تفصيلية عن الصندوق المطلوب خلال الأشهر الـ12
                          الأخيرة، والتي تشمل بيانات الإيداعات، السحوبات،
                          العوائد الاسمية، وطريقة توزيع الأصول فيه.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="card mb-0 p-2">
                  <div className="card-header bg-dark" id="headingFour">
                    <h4 className="m-0">
                      <a
                        href="#collapseFour"
                        className="collapsed text-primary"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        مصادر المعلومات وافتراضات النظام
                      </a>
                    </h4>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      <ul className=" p-2">
                        <li className=" p-2">
                          يعتمد النظام على بيانات عوائد صناديق التقاعد التي يتم
                          الإبلاغ عنها شهريًا إلى قسم سوق المال.
                        </li>
                        <li className=" p-2">
                          مؤشرات السوق المختارة كما يتم نشرها من قبل بورصة تل
                          أبيب
                        </li>
                        <li className=" p-2">
                          عائد اسمي إجمالي – يستخدم النظام بيانات العوائد
                          الاسمية دون خصم رسوم الإدارة.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
};

export default AccordionModal;
