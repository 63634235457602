import React from "react";

const ReusableInfoModal = ({ modalId, title, sections }) => {
  return (
    <div
      id={modalId}
      className="modal custom-modal fade"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-primary">{title}</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="accordion">
              {sections.map((section, index) => (
                <div className="card mb-0 p-2" key={index}>
                  <div className="card-header bg-dark" id={`heading${index}`}>
                    <h4 className="m-0">
                      <a
                        href={`#collapse${index}`}
                        className={`text-primary ${
                          section.isExpanded ? "" : "collapsed"
                        }`}
                        data-bs-toggle="collapse"
                        aria-expanded={section.isExpanded}
                        aria-controls={`collapse${index}`}
                      >
                        {section.header}
                      </a>
                    </h4>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`collapse ${section.isExpanded ? "show" : ""}`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">{section.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableInfoModal;
