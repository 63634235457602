import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { iniReader, bkmvReader } from "../../../utils/openformat/readFile";
import { Link } from "react-router-dom";
import Select from "react-select";
import useToasts from "../Accounting/components/Toasts";
import { file126Retreive } from "../../../utils/s126/read126File";
import retreiver from "../../../utils/a6111/fileRetreiver";
import iconv from "iconv-lite";
import useDatabase from "../../../hooks/useDatabase";

const Import6111 = () => {
  // const compRef=useRef()
  const { toastrSuccess, toastrError } = useToasts();
  const data = useSelector((state) => state.balance).data6111;
  const user = useSelector((state) => state.user).value;
  const companyData = [];
  const { setDocumentMultiLayer } = useDatabase();

  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);

  const [codingType, setCodingType] = useState("");
  const [companyName, setName] = useState("");
  const [companyName2, setName2] = useState("");

  const handleFileUpload = async () => {
    const decodedText = await handleDecoding(file);
    retreiver(decodedText, companyName);
    if (file2) {
      const decodedText2 = await handleDecoding(file2);
      retreiver(decodedText2, companyName2, false);
    }
  };

  const handleDecoding = async (fileToRead) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const decodedText = iconv.decode(uint8Array, codingType);
        resolve(decodedText);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error reading file."));
      };

      reader.readAsArrayBuffer(fileToRead);
    });
  };

  const options = [
    {
      value: "ISO-8859-8",
      label: "Windows ISO-8859-8",
    },
    // {
    //   // value: "CP862",
    //   value: "CP862",
    //   label: "Dos CP-862",
    // },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const hahdleSelectOption = (item) => {
    setCodingType(item.value);
  };

  const handleUploadFiles = () => {
    if (!companyName) {
      // compRef.current.focus()
      const el = document.getElementById("companyName");
      el.focus();
      toastrError("أدخل اسم الشركة قبل التحميل.");
      return;
    }

    if (!file || !codingType) {
      // alert("الرجاء اختيار نوع التكويد واختيار الملفات");
      toastrError("الرجاء اختيار نوع التكويد واختيار الملفات");
      return;
    }

    handleFileUpload();
    toastrSuccess("تم استيراد البيانات بنجاح!");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await setDocumentMultiLayer(
      "6111",
      data,
      data.caseNum,
      data.companyName,
      data.taxYear
    );
    // console.log(data);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle=" Import File 6111"
            title="Dashboard"
            subtitle="Import 6111"
          />

          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                {/* <span>نوع التكويد</span> */}
                <input
                  type="text"
                  placeholder="أدخل اسم الشركة للملف الأول"
                  value={companyName}
                  onChange={(e) => setName(e.target.value)}
                  id="companyName"
                />
                <input
                  type="text"
                  placeholder="أدخل اسم الشركة للملف الثاني"
                  value={companyName2}
                  onChange={(e) => setName2(e.target.value)}
                  id="companyName"
                />

                <Select
                  placeholder="اختر نوع التكويد"
                  onChange={hahdleSelectOption}
                  options={options}
                  styles={customStyles}
                />
                <button
                  className="btn btn-primary "
                  onClick={handleUploadFiles}
                >
                  رفع الملفات
                </button>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle"
                  >
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف 6111 السنة الحالية</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle"
                  >
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف 6111 السنة السابقة</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setFile2(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <form>
            <div className="row">
              {data?.list?.map((item, index) => (
                <div className="col-sm-6" key={index}>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {item.name}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue="MassDev's Technologies"
                      value={item.value}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              {data && user && (
                <button className="btn btn-success" onClick={handleSave}>
                  حفظ في قاعدة البيانات
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Import6111;
