import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import FundFilters from "../../../components/FundFilters";
import { base_url } from "../../../base_urls";
import axios from "axios";
import { FaRegCalendarCheck, FaShekelSign } from "react-icons/fa6";
import { formatNumbers } from "../../../utils/openformat/numbers";

const ProvidentFundsDetails = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [filtersArrays, setFiltersArray] = useState({});
  const [filter, setFilter] = useState({
    תחום: "",
  });
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    createColumns();
  }, []);

  const handleData = async () => {
    let arr = [];
    let url = "api/provFunds/pirttKopot/kopot-dtls.json";

    arr = await axios.get(base_url + url);

    setData(arr.data.result.records);
    setDisplayData(arr.data.result.records);
    handleCreateFiltersArrays(arr.data.result.records);
  };

  const createColumns = () => {
    const structure = [
      "תחום",
      "ח.פ. של חברה",
      "שם חברה",
      "מספר קופה -אישור מס הכנסה",
      "שם קופה / קרן",
      "שם קצר - קופה / קרן",
      "מספר מסלול השקעה",
      "שם מסלול ארוך",
      "שם מסלול קצר",
      "סוג מסלול השקעה",
      "התמחות ראשית של מסלול",
      "התמחות משנית של מסלול",
      "מספר ח.פ. של חברה מתפעלת",
      "שם חברה מתפעלת",
      "דואר אלקטרוני",
      "שם איש קשר",
      "טלפון",
      "פקס",
      "כתובת",
      "עיר",
      "מיקוד",
      "העברות נכנסות / יוצאות",
      "קוד בנק",
      "שם בנק",
      "מספר סניף",
      "מספר חשבון",
      "תאריך עדכון אחרון",
    ];

    const arr = [];
    structure.forEach((item) => {
      arr.push({
        title: item,
        dataIndex: item,
      });
    });

    setColumns(arr);
  };

  const handleCreateFiltersArrays = (data) => {
    const structure = [
      "תחום",
      // "FUND_NAME",
      // "REPORTING_YEAR",
      // "FUND_ID",
      // "MANAGING_CORPORATION_LEGAL_ID",
    ];
    let obj = getUniqueValues(data, structure);
    setFiltersArray(obj);
  };

  const handleFilterChange = (name, value) => {
    if (name === "reset") {
      setDisplayData(data);
      setFilter({
        FUND_NAME: "",
        MANAGING_CORPORATION_LEGAL_ID: "",
        REPORTING_YEAR: "",
      });
      return;
    }
    let obj = { ...filter, [name]: value };
    setFilter(obj);
    let list = [...data];
    console.log(obj);
    Object.keys(obj).forEach((key) => {
      if (obj[key]) list = list.filter((item) => item[key] == obj[key]);
    });

    setDisplayData(list);
    // if (list.length > 11) {
    //   setFilteredData(list[11]); // Safeguard for index
    // } else {
    //   setFilteredData(null);
    // }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid" style={{ overflowX: "auto" }}>
        <Breadcrumbs
          maintitle={title}
          title={t("dashboard")}
          subtitle={title}
        />

        {/* {filteredData && <Headers filteredData={filteredData} />} */}

        <FundFilters
          filtersArrays={filtersArrays}
          action={handleFilterChange}
        />

        <Table columns={columns} dataSource={displayData} />
      </div>
    </div>
  );
};

export default ProvidentFundsDetails;

function getUniqueValues(array, keys) {
  const result = {};
  keys.forEach((key) => {
    result[key] = [...new Set(array.map((item) => item[key]))];
  });
  return result;
}

const Headers = ({ filteredData }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-group m-b-30">
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">الفترة المالية</span>
                  </div>
                  <div>
                    <span className="text-success">
                      <FaRegCalendarCheck size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3 className=" mt-3">{filteredData.REPORTING_YEAR} - 12</h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">الايداعات الشهرية</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.DEPOSITS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.DEPOSITS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {/* {filteredData.DEPOSITS * 1000000} */}
                  {formatNumbers(filteredData.DEPOSITS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">مجموع السحوبات الشهرية</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.WITHDRAWLS < 0
                          ? "text-danger mt-3"
                          : "text-success mt-3"
                      }
                    >
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.WITHDRAWLS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.WITHDRAWLS * 1000000)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-group m-b-30">
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">مجموع التحويلات الداخلية </span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.INTERNAL_TRANSFERS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.INTERNAL_TRANSFERS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.INTERNAL_TRANSFERS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">صافي الايداعات الشهرية </span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.NET_MONTHLY_DEPOSITS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {/* {user.percentage} */}
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.NET_MONTHLY_DEPOSITS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {" "}
                  {formatNumbers(filteredData.NET_MONTHLY_DEPOSITS * 1000000)}
                </h3>
              </div>
            </div>
            <div className="card m-2">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span className="d-block">اجمالي الاصول</span>
                  </div>
                  <div>
                    <span
                      className={
                        filteredData.TOTAL_ASSETS < 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      <FaShekelSign size={20} />
                    </span>
                  </div>
                </div>
                <div className="progress mb-2" style={{ height: "2px" }}>
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={40}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <h3
                  className={
                    filteredData.TOTAL_ASSETS < 0
                      ? "text-danger mt-3"
                      : "text-success mt-3"
                  }
                >
                  {formatNumbers(filteredData.TOTAL_ASSETS * 1000000)}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
