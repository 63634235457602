import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const graphs = [
  {
    keys: [
      { key: "DEPOSITS", title: "الإيداعات", color: "#373651" },
      { key: "WITHDRAWLS", title: "السحوبات", color: "#E65A26" },
      {
        key: "INTERNAL_TRANSFERS",
        title: "التحويلات الداخلية",
        color: "#a1a1a1",
      },
    ],
    title: "التدفق المالي",
  },
  {
    keys: [
      { key: "MONTHLY_YIELD", title: "العائد الشهري", color: "#373651" },
      {
        key: "YEAR_TO_DATE_YIELD",
        title: "عائد منذ بداية العام",
        color: "#E65A26",
      },
      {
        key: "YIELD_TRAILING_3_YRS",
        title: "عائد آخر 3 سنوات",
        color: "#a1a1a1",
      },
      {
        key: "YIELD_TRAILING_5_YRS",
        title: "عائد آخر 5 سنوات",
        color: "#a1b1a1",
      },
    ],
    title: "العوائد",
  },
  {
    keys: [
      { key: "ALPHA", title: "ألفا", color: "#373651" },
      { key: "SHARPE_RATIO", title: "نسبة شارب", color: "#E65A26" },
    ],
    title: "المؤشرات",
  },
  {
    keys: [
      {
        key: "STANDARD_DEVIATION",
        title: "الإنحراف المعياري",
        color: "#373651",
      },
    ],
    title: "الإنحراف المعياري",
  },
  {
    keys: [
      {
        key: "STOCK_MARKET_EXPOSURE",
        title: "التعرض لسوق الأسهم",
        color: "#373651",
      },
      {
        key: "FOREIGN_EXPOSURE",
        title: "التعرض للأصول الأجنبية",
        color: "#E65A26",
      },
      {
        key: "FOREIGN_CURRENCY_EXPOSURE",
        title: "التعرض للعملات الأجنبية",
        color: "#a1a1a1",
      },
    ],
    title: "التعرضات",
  },
];

const DashboardGraph = ({ current }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    handleCreateGraph();
  }, [current]);

  const handleCreateGraph = () => {
    const dataset = [];
    const main = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      legend: {
        display: false,
      },
    };

    graphs.forEach((graph) => {
      let list = [];
      graph.keys.forEach((item) => {
        list.push({
          label: item.title,
          data: getArrayKeyValueToArray(current, item.key),
          lineTension: 0.2,
          fill: false,
          borderColor: item.color,
          backgroundColor: item.color,
          borderWidth: 1,
        });
      });
      dataset.push({
        title: graph.title,
        ...main,
        datasets: list,
      });
    });
    // const datasets = [
    //   {
    //     label: "الإيداعات",
    //     data: getArrayKeyValueToArray(current, "DEPOSITS"),
    //     lineTension: 0.2,
    //     fill: false,
    //     borderColor: "#373651",
    //     backgroundColor: "#373651",
    //     borderWidth: 1,
    //   },
    //   {
    //     label: "السحوبات",
    //     data: getArrayKeyValueToArray(current, "WITHDRAWLS"),
    //     fill: false,
    //     lineTension: 0.2,
    //     legend: false,
    //     borderColor: "#E65A26",
    //     backgroundColor: "#E65A26",
    //     borderWidth: 1,
    //   },
    //   {
    //     label: "التحويلات الداخلية",
    //     data: getArrayKeyValueToArray(current, "INTERNAL_TRANSFERS"),
    //     fill: false,
    //     lineTension: 0.2,
    //     borderColor: "#a1a1a1",
    //     backgroundColor: "#a1a1a1",
    //     borderWidth: 1,
    //   },
    // ];

    setData(dataset);
  };

  const getArrayKeyValueToArray = (arr, key) => {
    let list = [];
    arr.forEach((item) => {
      list.push(item[key]);
    });
    return list;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          {data.map((item) => (
            <div className="col-md-6 text-center d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <h3 className="card-title">{item.title}</h3>
                  <Line data={item} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardGraph;
