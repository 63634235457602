import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { base_url } from "../../../../base_urls";
import Header from "../../../layout/Header";
import { RiLock2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { arrayToObject126 } from "../../../../utils/s126/arrays126";
const CapitalMarket = () => {
  const data = [
    {
      id: 1,
      title: "صناديق الادخار",
      department: "صناديق",
      type: "دخول ",
      location: " صناديق الادخار",
      salary: "",
      posted: "",
      path: "/capital-market/savings",
    },
    {
      id: 2,
      title: "صناديق التقاعد ",
      department: "صناديق ",
      type: "دخول",
      location: "صناديق التقاعد ",
      salary: "",
      posted: "",
      path: "/capital-market/pensions",
    },
    {
      id: 3,
      title: "صناديق التأمين ",
      department: "صناديق ",
      type: "locked",
      location: "صناديق التأمين ",
      salary: "",
      posted: "",
      path: "#",
    },
  ];

  return (
    <div>
      <div className="main-wrapper">
        <Header />
        <div className="page-wrapper job-wrapper">
          {/* Page Content */}
          <div className="content container">
            {/* Page Header */}
            <Breadcrumbs
              maintitle="سوق رأس المال"
              title="الرئيسية"
              subtitle="صناديق ادخار و تقاعد"
              modal="#accordion-modal"
              name="توضيح "
            />
            {/* /Page Header */}
            <div className="row">
              <div className="row">
                {Array.isArray(data) && data.length > 0
                  ? data.map((job, index) => (
                      <div className="col-md-6" key={index}>
                        <Link className="job-list" to={job.path}>
                          <div className="job-list-det">
                            <div className="job-list-desc">
                              <h3 className="job-list-title">{job.title}</h3>
                              <h4 className="job-department">
                                {job.department}
                              </h4>
                            </div>
                            <div className="job-type-info">
                              <span className="job-types">
                                {job.type == "locked" ? (
                                  <RiLock2Fill />
                                ) : (
                                  job.type
                                )}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="job-list-footer">
                            <ul>
                              <li>
                                <i className="fa-solid fa-signs-post" />{" "}
                                {job.location}
                              </li>
                              <li>
                                <i className="fa-regular fa-file" />{" "}
                                {job.salary}
                              </li>
                              <li>
                                <i className="fa-regular fa-clock" />{" "}
                                {job.posted}
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </div>
                    ))
                  : " <p>No job listings available.</p>"}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </div>
    </div>
  );
};

export default CapitalMarket;
