import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const useFundData = (base_url, fundYear, fundId, isPensionPage) => {
  const [title, setTitle] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [filtersArrays, setFiltersArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [allData, setAlData] = useState([]);
  const handleData = async () => {
    setLoading(true);

    try {
      if (isPensionPage) {
        // Fetch pension data
        const [
          pensions2023Response,
          pensions2024Response,
          pensions2022Response,
        ] = await Promise.all([
          axios.get(base_url + "api/provFunds/pensions/2023p.json"),
          axios.get(base_url + "api/provFunds/pensions/2024p.json"),
          axios.get(base_url + "api/provFunds/pensions/1999_2022p.json"),
        ]);

        const pensionArr = [
          ...(pensions2024Response.data || []),
          ...(pensions2023Response.data || []),
          ...(pensions2022Response.data || []),
        ];
        const filteredPensionData = pensionArr.filter(
          (item) => item.FUND_ID == fundId
        );
        setAlData(pensionArr);
        setData(filteredPensionData);
        setDisplayData(filteredPensionData);

        handleCreateFiltersArrays(filteredPensionData);
        const thisyear = filteredPensionData.filter(
          (item) => item.REPORTING_YEAR == fundYear
        );
        setDisplayData(thisyear);
      } else {
        // Fetch regular fund data
        const [funds2024Response, funds2023Response, fundsTo2022Response] =
          await Promise.all([
            axios.get(base_url + "api/provFunds/gemel/2024.json"),
            axios.get(base_url + "api/provFunds/gemel/2023.json"),
            axios.get(base_url + "api/provFunds/gemel/1999_2022.json"),
          ]);

        const fundArr = [
          ...(funds2024Response.data || []),
          ...(funds2023Response.data || []),
          ...(fundsTo2022Response.data || []),
        ];
        const filteredFundData = fundArr.filter(
          (item) => item.FUND_ID == fundId
        );
        setAlData(fundArr);
        setData(filteredFundData);
        setDisplayData(filteredFundData);
        handleCreateFiltersArrays(filteredFundData);
        const thisyear = filteredFundData.filter(
          (item) => item.REPORTING_YEAR == fundYear
        );
        setDisplayData(thisyear);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Create columns
  useEffect(() => {
    const createColumns = () => {
      const translatedKeys = {
        FUND_ID: "معرف الصندوق",
        FUND_NAME: "اسم الصندوق",
        FUND_CLASSIFICATION: "تصنيف الصندوق",
        CONTROLLING_CORPORATION: "الشركة المسيطرة",
        MANAGING_CORPORATION: "الشركة المديرة",
        REPORT_PERIOD: "فترة التقرير",
        INCEPTION_DATE: "تاريخ التأسيس",
        TARGET_POPULATION: "الفئة المستهدفة",
        SPECIALIZATION: "التخصص",
        SUB_SPECIALIZATION: "التخصص الفرعي",
        DEPOSITS: "الإيداعات",
        WITHDRAWLS: "السحوبات",
        INTERNAL_TRANSFERS: "التحويلات الداخلية",
        NET_MONTHLY_DEPOSITS: "صافي الإيداعات الشهرية",
        TOTAL_ASSETS: "إجمالي الأصول",
        AVG_ANNUAL_MANAGEMENT_FEE: "متوسط رسوم الإدارة السنوية",
        AVG_DEPOSIT_FEE: "متوسط رسوم الإيداع",
        MONTHLY_YIELD: "العائد الشهري",
        YEAR_TO_DATE_YIELD: "عائد منذ بداية العام",
        YIELD_TRAILING_3_YRS: "عائد آخر 3 سنوات",
        YIELD_TRAILING_5_YRS: "عائد آخر 5 سنوات",
        AVG_ANNUAL_YIELD_TRAILING_3YRS: "متوسط العائد السنوي لآخر 3 سنوات",
        AVG_ANNUAL_YIELD_TRAILING_5YRS: "متوسط العائد السنوي لآخر 5 سنوات",
        STANDARD_DEVIATION: "الانحراف المعياري",
        ALPHA: "ألفا",
        SHARPE_RATIO: "نسبة شارب",
        LIQUID_ASSETS_PERCENT: "نسبة الأصول السائلة",
        STOCK_MARKET_EXPOSURE: "التعرض لسوق الأسهم",
        FOREIGN_EXPOSURE: "التعرض للأصول الأجنبية",
        FOREIGN_CURRENCY_EXPOSURE: "التعرض للعملات الأجنبية",
        MANAGING_CORPORATION_LEGAL_ID: "المعرف القانوني للشركة المديرة",
        REPORTING_YEAR: "سنة التقرير",
      };

      const cols = Object.keys(translatedKeys).map((key) => ({
        title: translatedKeys[key],
        dataIndex: key,
      }));

      setColumns(cols);
    };

    createColumns();
  }, []);

  // Create filter options
  const handleCreateFiltersArrays = (data) => {
    const keysToFilter = ["REPORTING_YEAR"];
    const uniqueValues = keysToFilter.reduce((acc, key) => {
      acc[key] = [...new Set(data.map((item) => item[key]))];
      return acc;
    }, {});
    setFiltersArray(uniqueValues);
  };

  const filterByYear = (year) => {
    if (data && data.length > 0) {
      const filtered = data.filter((item) => item.REPORTING_YEAR == year);
      setDisplayData(filtered);
      // console.log("filtered by year is : ", filtered);
    }
  };

  return {
    title,
    columns,
    allData,
    data,
    displayData,
    filtersArrays,
    loading,
    handleData,
    filterByYear,
  };
};

export default useFundData;
