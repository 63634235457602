/**
 * Basic Table
 */
import React, { Component, Fragment, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { FaFileExcel } from "react-icons/fa";

// import ReactTooltip from 'react-tooltip';
export default function Table23() {
  const tableRef = useRef(null);
  return (
    <div className="card tax-card ">
      <div className="card-body">
        <div className="card-header">
          <h4 className="card-title mb-0 text-center privacy">
            شرائح ضريبة الدخل لعام 2023
            <span style={{ float: "left" }}>
              <DownloadTableExcel
                filename="MassPortal_tax-2023"
                sheet="tax-data"
                currentTableRef={tableRef.current}
              >
                <button className="btn " data-tip data-for="excel-tip">
                  <FaFileExcel size={25} className="privacy " />
                </button>
              </DownloadTableExcel>
            </span>
          </h4>
          <span>
            {" "}
            قيمة نقطة التزكية : <b className="privacy"> 235</b>
          </span>
        </div>
        {/* <ReactTooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
              </ReactTooltip> */}
        <div className="table-responsive">
          <table
            className="table table-nowrap table-striped mb-0"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th>الراتب ( شيكل جديد )</th>
                <th>الراتب التراكمي</th>
                <th>معدل الضريبة</th>
                <th>الضريبة </th>
                <th>الضريبة التراكمية</th>
                {/* <th>Country</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>من 0 الى 6.790</td>
                <td>6.790</td>
                <td>10.0%</td>
                <td>679</td>
                <td>679</td>
              </tr>
              <tr>
                <td>
                  <span className="privacy">+</span> 2.940
                </td>
                <td>9.730</td>
                <td>14.0%</td>
                <td>412</td>
                <td>1.091</td>
              </tr>
              <tr>
                <td>
                  <span className="privacy">+</span> 5.890
                </td>
                <td>15.620 </td>
                <td>20.0%</td>
                <td>1.178</td>
                <td>2.269</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <span className="privacy">+</span> 6.090
                </td>
                <td>21.710</td>
                <td>31.0%</td>
                <td>1.888</td>
                <td>4.157</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <span className="privacy">+</span> 23.470
                </td>
                <td>45.180</td>
                <td>35.0%</td>
                <td>8.215</td>
                <td>12.371</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <span className="privacy">+</span> 13.010{" "}
                </td>
                <td>58.190</td>
                <td>47.0%</td>
                <td>6.115</td>
                <td>18.486</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
