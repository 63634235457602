import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatNumbers } from "../../../../../utils/openformat/numbers";

const UserDataTable = ({ data }) => {
  // console.log(data);
  // const data = [
  //   {
  //     id: 1,
  //     jobtitle: "Web Developer",
  //     department: "Development",
  //     startdate: "1 Jan 2023",
  //     expirydate: "31 Feb 2023",
  //     jobtype: "Full Time",
  //     status: "Open",
  //   },
  //   {
  //     id: 2,
  //     jobtitle: "Web Designer",
  //     department: "Designing",
  //     startdate: "18 Mar 2023",
  //     expirydate: "31 May 2023",
  //     jobtype: "Part Time",
  //     status: "Closed",
  //   },
  //   {
  //     id: 3,
  //     jobtitle: "Android Developer",
  //     department: "Android",
  //     startdate: "1 Apr 2023",
  //     expirydate: "31 March 2023",
  //     jobtype: "Internship",
  //     status: "Cancelled",
  //   },
  // ];

  const columns = [
    {
      title: "السنة",
      dataIndex: "year",
      // sorter: (a, b) => a.id.length - b.id.length,
      // render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: "عدد الشهور",
      dataIndex: "months",
      // render: (text) => <Link to="/app/administrator/job-details">{text}</Link>,
      // sorter: (a, b) => a.jobtitle.length - b.jobtitle.length,
    },

    {
      title: "إجمالي الأصول",
      dataIndex: "totalAssets",
      // sorter: (a, b) => a.department.length - b.department.length,
      render: (text, record, index) => (
        <span>{formatNumbers(text * 1 * 1000000)}</span>
      ),
    },
  ];

  const [current, setCurrent] = useState([]);

  useEffect(() => {
    if (data.length) handleData();
  }, [data]);

  const handleData = () => {
    const list = [];
    const obj = {};
    data.forEach((item) => {
      if (!obj[item.REPORTING_YEAR]) {
        const months = data.filter(
          (el) => el.REPORTING_YEAR == item.REPORTING_YEAR
        );
        obj[item.REPORTING_YEAR] = {
          year: item.REPORTING_YEAR,
          months: months.length,
          totalAssets: months[months.length - 1].TOTAL_ASSETS,
        };
      }
    });

    Object.keys(obj).forEach((key) => {
      list.push(obj[key]);
    });

    setCurrent(list);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={current || []}
            rowKey={(record) => record.year}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDataTable;
