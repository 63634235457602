// Modal data for "Alpha"
const alphaModalData = {
  modalId: "alphaModal",
  title: "ألفا (Alpha) في سياق صناديق الادخار والاستثمار",
  sections: [
    {
      header: "ما هي ألفا (Alpha)؟",
      content: (
        <p>
          ألفا (Alpha) في سياق صناديق الادخار والاستثمار تشير إلى مقياس أداء
          يُستخدم لتقييم مدى نجاح مدير الصندوق في تحقيق عوائد إضافية مقارنةً
          بالمخاطر التي تحملها الصندوق أو مقارنةً بالمؤشر المرجعي (Benchmark)
          المستخدم.
        </p>
      ),
      isExpanded: true,
    },
    {
      header: "التفسير",
      content: (
        <ul>
          <li>
            <b>ألفا الإيجابية (+):</b> تعني أن الصندوق حقق أداءً أفضل من المتوقع
            بناءً على المخاطر التي تحملها، وهذا يدل على نجاح مدير الصندوق في
            تحقيق قيمة مضافة للمستثمرين.
          </li>
          <li>
            <b>ألفا السلبية (-):</b> تعني أن الصندوق حقق أداءً أقل من المتوقع،
            مما قد يشير إلى ضعف في الإدارة الاستثمارية.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
    {
      header: "كيفية حساب ألفا",
      content: (
        <>
          <p>
            صيغة ألفا هي:
            <br />
            <code>
              Alpha = Actual Return − (Risk-Free Rate + β × (Market Return −
              Risk-Free Rate))
            </code>
          </p>
          <ul>
            <li>
              <b>Actual Return:</b> العائد الفعلي للصندوق.
            </li>
            <li>
              <b>Risk-Free Rate:</b> معدل العائد على الاستثمار الخالي من المخاطر
              (مثل السندات الحكومية).
            </li>
            <li>
              <b>Beta (β):</b> مقياس المخاطر المرتبطة بالصندوق مقارنةً بالسوق.
            </li>
            <li>
              <b>Market Return:</b> العائد المتوقع من السوق.
            </li>
          </ul>
        </>
      ),
      isExpanded: false,
    },
    {
      header: "مثال عملي",
      content: (
        <>
          <p>
            إذا كان الصندوق قد حقق عائدًا بنسبة 10%، وكان معدل العائد الخالي من
            المخاطر 3%، والعائد السوقي 8%، وبيتا للصندوق 1.2، فإن ألفا تُحسب
            كالتالي:
          </p>
          <code>Alpha = 10% − (3% + 1.2 × (8% − 3%))</code>
        </>
      ),
      isExpanded: false,
    },
    {
      header: "دلالات ألفا",
      content: (
        <ul>
          <li>
            <b>ألفا عالية وإيجابية:</b> تدل على أن الصندوق يضيف قيمة حقيقية
            مقارنةً بالمخاطر.
          </li>
          <li>
            <b>ألفا منخفضة أو سلبية:</b> تشير إلى أن الصندوق لم يحقق عوائد
            تتناسب مع المخاطر، أو أن مدير الصندوق لم يكن فعالًا.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
    {
      header: "أهمية ألفا في صناديق الادخار",
      content: (
        <ul>
          <li>تساعد المستثمرين في تقييم جودة إدارة الصندوق.</li>
          <li>
            تُستخدم للمقارنة بين صناديق مختلفة لمعرفة أيها يحقق عوائد أعلى
            بالمخاطر نفسها.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
  ],
};

export default alphaModalData;

// Modal data for "Internal Transfers"
const internalTransfersData = {
  modalId: "infoModalInternalTransfers",
  title: "التحويلات الداخلية",
  sections: [
    {
      header: "التحويلات الداخلية في صناديق الادخار",
      content: (
        <>
          <p>
            التحويلات الداخلية في صناديق الادخار تشير إلى عمليات يتم فيها نقل
            الأموال أو الأصول داخل نفس النظام أو المؤسسة، دون أن تغادر إلى جهة
            خارجية. في سياق صناديق الادخار، يمكن أن تحمل هذه التحويلات عدة
            معانٍ، منها:
          </p>
          <ol>
            <li>
              <b>التحويل بين الخطط الاستثمارية:</b> يقوم المدخر بنقل أمواله بين
              خطط أو برامج استثمارية مختلفة داخل نفس صندوق الادخار، مثل الانتقال
              من خطة ذات مخاطر منخفضة إلى خطة ذات مخاطر أعلى أو العكس، بناءً على
              تغيّر احتياجاته أو رؤيته للسوق.
            </li>
            <li>
              <b>التحويل بين الحسابات:</b> إذا كان للمدخر أكثر من حساب أو أكثر
              من نوع ادخار (مثل حساب ادخار تقاعدي وآخر للتعليم) داخل نفس
              المؤسسة، فقد يُحوِّل أموالًا بين هذه الحسابات.
            </li>
            <li>
              <b>التحويلات الإدارية:</b> قد تشمل التحويلات الداخلية قرارات
              إدارية يقوم بها مديرو الصندوق لنقل الأصول أو إعادة توزيعها لتحسين
              الأداء العام للصندوق.
            </li>
          </ol>
        </>
      ),
      isExpanded: true,
    },
    {
      header: "ملاحظات:",
      content: (
        <ol>
          <li>
            <b>لا تؤثر هذه التحويلات على المستثمر مباشرةً:</b> في حال كانت ضمن
            إدارة الصندوق، ولكنها قد تكون مؤشرًا على تغييرات استراتيجية.
          </li>
          <li>
            <b>قد تُطبق رسوم أو شروط معينة:</b> على بعض أنواع التحويلات
            الداخلية، لذلك يُنصح بمراجعة شروط الصندوق أو استشارة المستشار المالي
            قبل إجراء أي تحويل.
          </li>
        </ol>
      ),
      isExpanded: false,
    },
  ],
};
const sharpeRatioModalData = {
  modalId: "sharpeRatioModal",
  title: "معامل شارب (Sharpe Ratio)",
  sections: [
    {
      header: "ما هو معامل شارب؟",
      content: (
        <p>
          معامل شارب (Sharpe Ratio) هو مقياس مالي يستخدم لتقييم الأداء
          الاستثماري لصندوق الادخار أو أي استثمار آخر مقارنة بالمخاطر التي يتعرض
          لها. الهدف منه هو قياس العائد الإضافي الذي يولده الاستثمار مقابل كل
          وحدة من المخاطر التي يتحملها المستثمر.
        </p>
      ),
      isExpanded: true,
    },
    {
      header: "الصيغة الحسابية لمعامل شارب",
      content: (
        <>
          <p>
            <code>Sharpe Ratio = (Rp − Rf) / σp</code>
          </p>
          <ul>
            <li>
              <b>Rp:</b> العائد المتوقع أو الفعلي للصندوق (Return of the
              portfolio).
            </li>
            <li>
              <b>Rf:</b> معدل العائد الخالي من المخاطر (Risk-free rate)، مثل
              عائد السندات الحكومية.
            </li>
            <li>
              <b>σp:</b> الانحراف المعياري للعائد (Standard deviation of the
              portfolio returns)، وهو مقياس للمخاطر.
            </li>
          </ul>
        </>
      ),
      isExpanded: false,
    },
    {
      header: "معنى معامل شارب في صناديق الادخار",
      content: (
        <ul>
          <li>
            <b>قياس الأداء المعدل بالمخاطر:</b> يقيس مقدار العائد الذي يحققه
            الصندوق بالنسبة لكل وحدة من المخاطر. كلما كان معامل شارب أعلى، كان
            الصندوق أكثر كفاءة في تحقيق العوائد بالنسبة إلى المخاطر.
          </li>
          <li>
            <b>المقارنة بين الصناديق:</b> يُستخدم معامل شارب لمقارنة صناديق
            الادخار المختلفة. الصندوق ذو معامل شارب الأعلى يُعتبر خيارًا أفضل
            إذا كان المستثمر يسعى لتحقيق عوائد عالية مع تحمل مخاطر منخفضة
            نسبيًا.
          </li>
          <li>
            <b>اتخاذ القرارات الاستثمارية:</b> يساعد المستثمرين ومديري الصناديق
            في اتخاذ قرارات مدروسة بشأن اختيار أو تعديل استراتيجية الاستثمار.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
    {
      header: "كيفية تفسير القيم",
      content: (
        <ul>
          <li>
            <b>معامل شارب إيجابي كبير (&gt;1):</b> يعني أن الصندوق يولد عوائد
            جيدة مقابل المخاطر التي يتحملها.
          </li>
          <li>
            <b>معامل شارب قريب من 0:</b> يشير إلى أن العوائد الإضافية ليست كبيرة
            مقارنة بالمخاطر.
          </li>
          <li>
            <b>معامل شارب سلبي:</b> يعني أن أداء الصندوق أسوأ من معدل العائد
            الخالي من المخاطر.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
    {
      header: "مثال عملي",
      content: (
        <>
          <p>
            إذا كان العائد السنوي لصندوق ادخار معين هو 8%، ومعدل العائد الخالي
            من المخاطر هو 2%، والانحراف المعياري لعوائد الصندوق هو 5%، فإن معامل
            شارب يُحسب كالتالي:
          </p>
          <code>Sharpe Ratio = (8% − 2%) / 5% = 1.2</code>
          <p>
            هذا يشير إلى أن الصندوق يولد 1.2 وحدة من العائد الإضافي لكل وحدة من
            المخاطر، وهو أداء جيد نسبيًا.
          </p>
        </>
      ),
      isExpanded: false,
    },
    {
      header: "لماذا هو مهم؟",
      content: (
        <ul>
          <li>يساعد المستثمرين على اختيار الصندوق الأكثر كفاءة.</li>
          <li>
            يُبرز العلاقة بين العائد والمخاطر، مما يساعد في اتخاذ قرارات
            استثمارية أفضل.
          </li>
        </ul>
      ),
      isExpanded: false,
    },
  ],
};
const unavailableServiceModal = {
  modalId: "unavailableServiceModal",
  title: "مساعد AI",
  sections: [
    {
      header: "خدمة مساعد AI",
      content: <p>خدمة مساعد AI غير متوفرة في النسخة المجانية.</p>,
      isExpanded: true,
    },
  ],
};

// Export modal data
export {
  alphaModalData,
  internalTransfersData,
  sharpeRatioModalData,
  unavailableServiceModal,
};
