/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import useDatabase from "../../../hooks/useDatabase";
import { useDispatch } from "react-redux";
import { setIndexData } from "../../../user";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user).value;
  const indexRef = `users/${user.uid}/uploadeDataIndex`;

  const authData = useSelector((state) => state.user).auth;
  const { getData } = useDatabase();

  useEffect(() => {
    handleDataIndex();
  }, []);

  const handleDataIndex = async () => {
    const arr = await getData(indexRef);
    console.log(arr);
    if (arr.length) dispatch(setIndexData(arr));
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img
                            src={authData.photoURL || Avatar_02}
                            alt="User Image"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {authData.displayName}
                            </h3>
                            <h6 className="text-muted">{user.role}</h6>
                            <small className="text-muted">
                              {authData.email}
                            </small>
                            <div className="staff-id">
                              Employee ID : {user.idNumber}
                            </div>
                            <div className="small doj text-muted">
                              Date of Join : {authData.metadata.creationTime}
                            </div>
                            <div className="small doj text-muted">
                              Last login : {authData.metadata.lastSignInTime}
                            </div>
                            <div className="staff-msg">
                              <Link className="btn btn-custom" to="/call/chat">
                                Send Message
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">اسم الشركة:</div>
                              <div className="text">{user.companyName}</div>
                            </li>
                            <li>
                              <div className="title">الهاتف:</div>
                              <div className="text">
                                <Link to={`tel:${user.phone}`}>
                                  {user.phone}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">البريد الإلكتروني:</div>
                              <div className="text">
                                <Link to={`mailto:${user.email}`}>
                                  {user.companyEmail}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">المدينة:</div>
                              <div className="text">{user.city}</div>
                            </li>
                            <li>
                              <div className="title">العنوان:</div>
                              <div className="text">{user.address}</div>
                            </li>
                            <li>
                              <div className="title">الرمز البريدي:</div>
                              <div className="text">{user.postalCode}</div>
                            </li>
                            <li>
                              <div className="title">رقم ملف ض.ق.م:</div>
                              <div className="text">{user.taxNumber}</div>
                            </li>
                            <li>
                              <div className="title">رقم الملف الضريبي:</div>
                              <div className="text">{user.vatNumber}</div>
                            </li>
                            <li>
                              <div className="title">رقم ملف الاقتطاعات:</div>
                              <div className="text">{user.deductionNumber}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Projects
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      Bank &amp; Statutory
                      <small className="text-danger ms-1">(Admin Only)</small>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      ملفاتي
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <ProfileTab />
        </div>
      </div>
    </>
  );
};

export default Profile;
