import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { iniReader, bkmvReader } from "../../../utils/openformat/readFile";
import { Link } from "react-router-dom";
import Select from "react-select";
import useToasts from "../Accounting/components/Toasts";
import { file126Retreive } from "../../../utils/s126/read126File";
import iconv from "iconv-lite";
import useDatabase from "../../../hooks/useDatabase";

const Import126 = () => {
  const { toastrSuccess, toastrError } = useToasts();
  const { setDocumentMultiLayer } = useDatabase();
  const salary = useSelector((state) => state.salary);
  const user = useSelector((state) => state.user).value;
  const companyData = salary.type10;

  const [file, setFile] = useState(null);
  const [codingType, setCodingType] = useState("");

  const handleFileUpload = async () => {
    const decodedText = await handleDecoding();
    file126Retreive(decodedText);
  };

  const handleDecoding = async () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        const decodedText = iconv.decode(uint8Array, codingType);
        resolve(decodedText);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error reading file."));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const options = [
    {
      value: "ISO-8859-8",
      label: "Windows ISO-8859-8",
    },
    {
      // value: "CP862",
      value: "CP862",
      label: "Dos CP-862",
    },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const hahdleSelectOption = (item) => {
    setCodingType(item.value);
  };

  const handleUploadFiles = () => {
    if (!file || !codingType) {
      // alert("الرجاء اختيار نوع التكويد واختيار الملفات");
      toastrError("الرجاء اختيار نوع التكويد واختيار الملفات");
      return;
    }

    handleFileUpload();
    toastrSuccess("تم استيراد البيانات بنجاح!");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const data = { ...salary };
    const uploadData = JSON.stringify(data);
    await setDocumentMultiLayer(
      "126",
      { data: uploadData },
      companyData[0].value,
      companyData[8].value,
      companyData[3].value
    );
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Open Format"
            title="Dashboard"
            subtitle="Open Format"
          />

          <div className="file-cont-wrap mr-0">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <span>نوع التكويد</span>
                <Select
                  placeholder="اختر نوع التكويد"
                  onChange={hahdleSelectOption}
                  options={options}
                  styles={customStyles}
                />
                <button
                  className="btn btn-primary "
                  onClick={handleUploadFiles}
                >
                  رفع الملفات
                </button>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle"
                  >
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف 126</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <form style={{marginBlock:"50px"}}>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    اسم الشركة <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text" readOnly
                    defaultValue="MassDev's Technologies"
                    value={companyData?.employerName}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">رقم ملف الاستقطاع الضريبي</label>
                  <input
                    className="form-control "
                    defaultValue="000000000"
                    type="text" readOnly
                    value={companyData?.taxFileNumber}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">العنوان</label>
                  <input
                    className="form-control "
                    defaultValue="CANADA"
                    type="text" readOnly
                    value={`${companyData?.street} ${companyData?.buildingNumber}`}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">الدولة</label>
                  <input
                    className="form-control"
                    defaultValue="CANADA"
                    type="text" readOnly
                    value={companyData?.businessAddressCity}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">المدينة</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text" readOnly
                    value={companyData?.city}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">State/Province</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text" readOnly
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">الرمز البريدي</label>
                  <input
                    className="form-control"
                    defaultValue={91403}
                    type="text" readOnly
                    value={companyData?.postalCode}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    defaultValue="support@massarwe.com"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Phone Number</label>
                  <input
                    className="form-control"
                    defaultValue="02.623.1666"
                    type="text" readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">رقم الهاتف</label>
                  <input
                    className="form-control"
                    defaultValue="054.543.1666"
                    type="text" readOnly
                    value={companyData?.phone}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Fax</label>
                  <input
                    className="form-control"
                    defaultValue="02.6250648"
                    type="text" readOnly
                    value={companyData?.fax}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Website Url</label>
                  <input
                    className="form-control"
                    defaultValue="https://massbill.com/"
                    type="text" readOnly
                  />
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button className="btn btn-primary submit-btn">
                تحميل ملف جديد
              </button>
            </div>
          </form> */}

          <form>
            <div className="row">
              {companyData.map((item, index) => (
                <div className="col-sm-6" key={index}>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      {item.ar}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      readOnly
                      defaultValue="MassDev's Technologies"
                      value={item.value}
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>
          <div className="row">
            {salary && user && (
              <button className="btn btn-success" onClick={handleSave}>
                حفظ في قاعدة البيانات
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Import126;
