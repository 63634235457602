import React from "react";

const DashboardWidget = ({ data }) => {
  // const data = [
  //   {
  //     icon: "fa fa-file-text",
  //     value: 110,
  //     label: "Offered",
  //   },
  //   {
  //     icon: "fa fa-clipboard",
  //     value: 40,
  //     label: "Applied",
  //   },
  //   {
  //     icon: "fa fa-retweet",
  //     value: 374,
  //     label: "Visited",
  //   },
  //   {
  //     icon: "fa fa-floppy-disk",
  //     value: 220,
  //     label: "Saved",
  //   },
  // ];

  const headerData = [
    {
      icon: "fa fa-percent",
      value: data?.AVG_ANNUAL_MANAGEMENT_FEE || 0,
      label: "متوسط رسوم الإدارة السنوية	",
    },
    {
      icon: "fa fa-percent",
      value: data?.AVG_DEPOSIT_FEE || 0,
      label: "متوسط رسوم الإيداع	",
    },
    {
      icon: "fa fa-percent",
      value: data?.MONTHLY_YIELD || 0,
      label: "العائد الشهري	",
    },
    {
      icon: "fa fa-percent",
      value: data?.YEAR_TO_DATE_YIELD || 0,
      label: "عائد منذ بداية العام	",
    },
    {
      icon: "fa fa-percent",
      value: data?.YIELD_TRAILING_3_YRS || 0,
      label: "عائد آخر 3 سنوات		",
    },
    {
      icon: "fa fa-percent",
      value: data?.YIELD_TRAILING_5_YRS || 0,
      label: "عائد آخر 5 سنوات		",
    },
    {
      icon: "fa fa-percent",
      value: data?.AVG_ANNUAL_YIELD_TRAILING_3YRS || 0,
      label: " متوسط العائد السنوي لآخر 3 سنوات		",
    },
    {
      icon: "fa fa-percent",
      value: data?.AVG_ANNUAL_YIELD_TRAILING_5YRS || 0,
      label: "متوسط العائد السنوي لآخر 5 سنوات	 	",
    },
  ];

  return (
    <div className="row">
      {headerData.map((widget, index) => (
        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={index}>
          <div className="card dash-widget">
            <div className="card-body">
              <div className="dash-widget-info text-right">
                <h3
                  className={
                    widget.value < 0 ? "text-danger " : "text-success "
                  }
                >
                  {widget.value}
                </h3>
                <span>{widget.label}</span>
              </div>
              <span className="dash-widget-icon">
                <i className={widget.icon} />
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardWidget;
