import React from "react";
import { Link } from "react-router-dom";

const WarningModal = (props) => {
  return (
    <>
      {/* Warning Alert Modal */}
      <div
        id="warning-alert-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body p-4">
              <div className="text-center">
                <i className="dripicons-warning h1 text-warning" />
                <h4 className="mt-2">غير متاح! </h4>
                <p className="mt-3">الطباعة غير متاحة في النسخة المجانية</p>
                <button
                  type="button"
                  className="btn btn-warning my-2"
                  data-bs-dismiss="modal"
                >
                  رجوع
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/* /.modal */}
    </>
  );
};

export default WarningModal;
