import React, { useEffect, useState } from "react";
import AptitudeTable from "./AptitudeTable";
import { Link } from "react-router-dom";
import ScheduleTable from "./ScheduleTable";
import { base_url } from "../../../../../../../base_urls";
import axios from "axios";

const InterviewingTables = ({ id }) => {
  const [activeTab, setActiveTab] = useState("home");
  const [data, setData] = useState(null);

  useEffect(() => {
    handleData();
  }, [id]);

  const handleData = async () => {
    const obj = await axios.get(
      base_url + "api/provFunds/pirttKopot/kopot-dtls.json"
    );
    let arr = obj.data.result.records;

    let current = arr.filter((item) => item["ח.פ. של חברה"] === id);
    setData(current[0]);
  };

  return (
    <div>
      <div className="row">
        {data &&
          Object.keys(data).map((key) => (
            <div className="col-sm-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  {key} <span className="text-danger"></span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  // defaultValue="Dreamguy's Technologies"
                  // name="companyName"
                  value={data[key]}
                  readOnly
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InterviewingTables;
