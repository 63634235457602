import React from "react";
import Select from "react-select";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const Localization = () => {
  const selectDate = [
    { label: "15/05/2023", value: "option1" },
    { label: "15.05.2023", value: "option2" },
    { label: "15-05-2023", value: "option3" },
    { label: "05/15/2023", value: "option4" },
    { label: "2023/05/15", value: "option5" },
    { label: "2023-05-15", value: "option6" },
    { label: "May 15 2023", value: "option7" },
    { label: "15 May 2023", value: "option8" },
  ];
  const selectUSA = [
    { label: "USA", value: "option1" },
    { label: "United Kingdom", value: "option2" },
  ];
  const selectUTC = [
    { label: "(UTC +5:30) Antarctica/Palmer", value: "option1" },
  ];
  const selectEnglish = [
    { label: "English", value: "option1" },
    { label: "French", value: "option2" },
  ];
  const selectUSD = [
    { label: "USD", value: "option1" },
    { label: "Pound", value: "option2" },
    { label: "EURO", value: "option3" },
    { label: "Ringgit", value: "option4" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              {/* Page Header */}
              <Breadcrumbs maintitle="معلومات الحساب" />
              {/* /Page Header */}
              <form>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        تاريخ إنشاء الحساب
                      </label>
                      <input className="form-control" readOnly type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">اسم المستخدم</label>
                      <input className="form-control" readOnly type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        البريد الإلكتروني
                      </label>
                      <input className="form-control" readOnly type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">آخر تسجيل دخول</label>
                      <input className="form-control" readOnly type="text" />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Currency Code</label>
                      <Select
                        options={selectUSD}
                        placeholder="USD"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Currency Symbol</label>
                      <input
                        className="form-control"
                        readOnly
                        defaultValue="$"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Save
                      </button>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default Localization;
