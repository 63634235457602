import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

const FundFilters = ({ filtersArrays, action }) => {
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState({});
  const translatedKeys = {
    FUND_ID: "معرف الصندوق",
    FUND_NAME: "اسم الصندوق",
    FUND_CLASSIFICATION: "تصنيف الصندوق",
    CONTROLLING_CORPORATION: "الشركة المسيطرة",
    MANAGING_CORPORATION: "الشركة المديرة",
    REPORT_PERIOD: "فترة التقرير",
    INCEPTION_DATE: "تاريخ التأسيس",
    TARGET_POPULATION: "الفئة المستهدفة",
    SPECIALIZATION: "التخصص",
    SUB_SPECIALIZATION: "التخصص الفرعي",
    DEPOSITS: "الإيداعات",
    WITHDRAWLS: "السحوبات",
    INTERNAL_TRANSFERS: "التحويلات الداخلية",
    NET_MONTHLY_DEPOSITS: "صافي الإيداعات الشهرية",
    TOTAL_ASSETS: "إجمالي الأصول",
    AVG_ANNUAL_MANAGEMENT_FEE: "متوسط رسوم الإدارة السنوية",
    AVG_DEPOSIT_FEE: "متوسط رسوم الإيداع",
    MONTHLY_YIELD: "العائد الشهري",
    YEAR_TO_DATE_YIELD: "عائد منذ بداية العام",
    YIELD_TRAILING_3_YRS: "عائد آخر 3 سنوات",
    YIELD_TRAILING_5_YRS: "عائد آخر 5 سنوات",
    AVG_ANNUAL_YIELD_TRAILING_3YRS: "متوسط العائد السنوي لآخر 3 سنوات",
    AVG_ANNUAL_YIELD_TRAILING_5YRS: "متوسط العائد السنوي لآخر 5 سنوات",
    STANDARD_DEVIATION: "الانحراف المعياري",
    ALPHA: "ألفا",
    SHARPE_RATIO: "نسبة شارب",
    LIQUID_ASSETS_PERCENT: "نسبة الأصول السائلة",
    STOCK_MARKET_EXPOSURE: "التعرض لسوق الأسهم",
    FOREIGN_EXPOSURE: "التعرض للأصول الأجنبية",
    FOREIGN_CURRENCY_EXPOSURE: "التعرض للعملات الأجنبية",
    MANAGING_CORPORATION_LEGAL_ID: "معرف  الشركة المديرة",
    REPORTING_YEAR: "سنة التقرير",
  };
  useEffect(() => {
    handleSetFilters();
  }, [filtersArrays]);

  const handleSetFilters = () => {
    const list = [];
    Object.keys(filtersArrays).forEach((key) => {
      list.push({ title: key, options: arrToOptions(filtersArrays[key]) });
    });

    setFilters(list);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleFilterChange = (name, value) => {
    setSelected((values) => ({ ...values, [name]: value }));

    action(name, value.value);
  };

  return (
    <>
      <div className="row filter-row justify-content-start">
        {filters.map((item, index) => (
          <div
            className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12"
            key={index}
          >
            <div className="input-block form-focus select-focus ">
              <Select
                options={item.options}
                placeholder={
                  translatedKeys[item.title] == undefined
                    ? ` اختر ${item.title}`
                    : ` اختر ${translatedKeys[item.title]}`
                }
                styles={customStyles}
                value={selected?.[item.title] || null}
                onChange={(el) => handleFilterChange(item.title, el)}
              />
              <label className="focus-label">
                {translatedKeys[item.title]}
              </label>
            </div>
          </div>
        ))}

        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <Link
            to="#"
            className="btn btn-success btn-block w-100"
            onClick={() => {
              action("reset");
              setSelected({});
            }}
          >
            إلغاء الفلاتر
          </Link>
        </div>
      </div>
    </>
  );
};

export default FundFilters;

const arrToOptions = (arr) => {
  const list = [];
  arr.forEach((item) => {
    list.push({ label: item, value: item });
  });
  return list;
};
